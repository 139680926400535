import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('@/views/technology.vue')
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('@/views/BusinessBike.vue')
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('@/views/QuestionView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return {x: 0, y: 0}
    }
  }
})

export default router

<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      window.onresize = () => {
        // 设置基准值
        let sUserAgent = navigator.userAgent.toLowerCase()
        let bIsWM = /mobile/.test(sUserAgent)
        let baseFontSize = 0; // 这里假设设计图宽度为375px，所以基准值为20px
        if(bIsWM) {
          baseFontSize = document.documentElement.clientWidth / 375 * 50
        }else{
          baseFontSize = document.documentElement.clientWidth / 1200 * 62.5
        }
        document.documentElement.style.fontSize = `${baseFontSize}px`;
      }
    },
    destroyed() {
      localStorage.removeItem('language')
    }
  }
</script>

<style lang="scss">
@import "@/assets/css/style.css";

@media screen and (min-width: 1280px){
  .header .header__container {
    width: 12rem;
    position: relative
  }
}
@media screen and (min-width: 981px){
  .header-pc{
    display: flex !important;
  }
  .header-h5{
    display: none !important;
  }
  .header {
    width: 100%;
    height: 80px;
    margin: 0 auto;
    z-index: 11;
    transition: background .3s cubic-bezier(.51,.01,0,1),-webkit-transform .6s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1),background .3s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1),background .3s cubic-bezier(.51,.01,0,1),-webkit-transform .6s cubic-bezier(.51,.01,0,1);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
  }

  .header.fixed {
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .header.shadow.float-up {
    box-shadow: 0rem .04rem .07rem 0rem rgba(0,0,0,.07)
  }



  .header.float-down {
    background: #fff;
    -webkit-transform: translate(-50%,-150%);
    -ms-transform: translate(-50%,-150%);
    transform: translate(-50%,-150%)
  }

  .header.float-up {
    background: #fff;
    border: .01rem solid #f5f5f5
  }

  .header.float-up .move {
    background: #0096ff;
    position: relative
  }

  .header.float-up .header__menu-item a {
    color: #999
  }

  .header.float-up .header__menu-item a.active {
    color: #666
  }

  .header.float-up .header__menu-item a::after {
    background: #0096ff
  }

  .header-logo-container {
    display: inline-block
  }

  .header__logo {
    height: 40px;
    width: 175px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    top: 50%
  }

  .header__menu {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
  }

  .header__menu .move {
    border-radius: 4px;
    height: 2px;
    width: 50px;
    background-color: #fff;
    position: absolute;
    left: 25px;
    top: 60px;
    transition: left .2s ease-in-out 0s;
    pointer-events: none;
    z-index: -1
  }

  .header__menu .header__menu-item:nth-child(1):hover~.move {
    left: 35px!important
  }

  .header__menu .header__menu-item:nth-child(2):hover~.move {
    left: 155px!important
  }

  .header__menu .header__menu-item:nth-child(3):hover~.move {
    left: 275px!important
  }

  .header__menu .header__menu-item:nth-child(4):hover~.move {
    left: 395px!important
  }

  .header__menu .header__menu-item:nth-child(5):hover~.move {
    left: 515px!important
  }

  .header__menu .header__menu-item:nth-child(6):hover~.move {
    left: 525px!important
  }

  .header__menu .header__menu-item:nth-child(7):hover~.move {
    left: 625px!important
  }

  .header__menu .left-25 {
    left: 35px
  }

  .header__menu .left-125 {
    left: 155px
  }

  .header__menu .left-225 {
    left: 275px
  }

  .header__menu .left-325 {
    left: 395px
  }

  .header__menu .left-425 {
    left: 515px
  }

  .header__menu .left-525 {
    left: 525px
  }

  .header__menu .left-625 {
    left: 625px
  }

  .header__menu-item {
    float: left;
    line-height: 80px;
    width: 120px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    z-index: 15
  }

  .header__menu-item:hover~.header__menu-item .list {
    pointer-events: none
  }

  .header__menu-item .hover-target {
    display: block;
    width: 100%;
    height: 100%;
    z-index: inherit
  }

  .header__menu-item.with-child {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  .header__menu-item.with-child:hover .list {
    visibility: visible;
    -webkit-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    opacity: 1;
    top: 60px
  }

  .header__menu-item.with-child.to-left .list {
    right: .24rem!important;
    left: unset
  }

  .header__menu-item.with-child .list {
    visibility: hidden;
    position: absolute;
    top: 50px;
    left: 24px;
    opacity: 0;
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    list-style: none;
    background: #fff;
    color: #666;
    border-radius: 2px;
    white-space: nowrap;
    transition: all .4s ease;
    text-align: left;
    padding: 0;
    box-shadow: 0rem 0rem 6px 0rem rgba(0,0,0,.1);
    font-size: 14px;
    font-weight: 400;
    z-index: 14
  }

  .header__menu-item.with-child .list img {
    width: 22px;
    height: 22px
  }

  .header__menu-item.with-child .list>li {
    padding-left: 20px;
    padding-right: 36px;
    line-height: 42px;
    height: 42px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer
  }

  .header__menu-item.with-child .list>li img {
    margin-right: 10px
  }

  .header__menu-item.with-child .list>li:hover {
    background: #f5f8fb
  }

  .header__menu-item.with-child .list>li .integrity-qr-box {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -19px;
    top: 126px;
    transition: all .2s ease-in-out;
    padding: 20px 0
  }

  .header__menu-item.with-child .list>li .integrity-qr-box .integrity-qr {
    display: block;
    height: 180px;
    width: 180px
  }

  .header__menu-item.with-child .list .li_integrity:hover .integrity-qr-box {
    visibility: visible;
    opacity: 1
  }

  .header__menu-item.active,.header__menu-item:hover {
    font-weight: 600
  }

  .header__menu-item a {
    text-decoration: none;
    color: #fff;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    z-index: inherit
  }

  .header__menu-item a::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -.15rem;
    border-radius: .04rem;
    height: .04rem;
    width: .5rem;
    -webkit-transform: translateX(-50%) scale(0);
    -ms-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
    transition: .3s all ease-in-out;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #fff
  }

  .header__menu-item a.download {
    box-sizing: border-box;
    padding: .03rem .12rem;
    border-radius: .14rem;
    border: .01rem solid #fff
  }

  .header__menu-item a.download::after {
    content: none
  }
}
@media screen and (max-width: 980px){
  .header-pc{
    display: none !important;
  }
  .header-h5{
    display: block !important;
  }
  header {
    position: absolute;
    width: 100%;
    height: 80px;
    margin: 0 auto
  }

  header .header__top {
    width: 100%;
    text-align: center;
    height: .92rem;
    position: fixed;
    top: 0;
    z-index: 11;
    transition: background .3s cubic-bezier(.51,.01,0,1),-webkit-transform .6s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1),background .3s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1),background .3s cubic-bezier(.51,.01,0,1),-webkit-transform .6s cubic-bezier(.51,.01,0,1)
  }

  header .header__top .sandwich {
    width: .38rem;
    height: .34rem;
    position: absolute;
    left: .4rem;
    top: .32rem;
    cursor: pointer
  }

  header .header__top .logo {
    position: absolute;
    width: 2rem;
    height: .45rem;
    top: .24rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  header .header__top.shadow.float-up {
    box-shadow: 0rem .04rem .07rem 0rem rgba(0,0,0,.2)
  }

  header .header__top.float-down {
    background: #fff;
    -webkit-transform: translate(0,-150%);
    -ms-transform: translate(0,-150%);
    transform: translate(0,-150%)
  }

  header .header__top.float-up {
    background: #fff;
    border: 1px solid #f5f5f5
  }

  header .header__mask {
    display: none;
    background: #000;
    opacity: .5;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 900
  }

  header .header__mask.show {
    display: block
  }

  header .header__drawer {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 980;
    width: 6.2rem;
    background-color: #fff;
    -webkit-transform: translate3d(-6.2rem,0,0);
    transform: translate3d(-6.2rem,0,0);
    height: 100vh;
    max-height: 100vh;
    transition: -webkit-transform .6s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1);
    transition: transform .6s cubic-bezier(.51,.01,0,1),-webkit-transform .6s cubic-bezier(.51,.01,0,1);
    padding: .96rem .32rem 0;
    opacity: 0;
    overflow: auto
  }

  header .header__drawer.active {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
  }

  header .header__drawer .header__drawer--close {
    cursor: pointer;
    position: absolute;
    left: .3rem;
    top: .38rem;
    width: .36rem;
    height: .36rem
  }
  .divider {
    width: 5.56rem;
    height: .02rem;
    background: #f5f5f5;
    margin-bottom: .6rem
  }
  .nav-list {
    width: 100%
  }

  .nav-list a {
    text-decoration: none;
    width: 100%;
    display: inline-block
  }

  .nav-list li {
    padding-left: .24rem;
    margin-bottom: .24rem
  }

  .nav-list li a {
    display: block;
    font-weight: 400;
    color: #333;
    border-bottom: none;
    height: .4rem;
    font-size: .28rem;
    color: #666;
    line-height: .4rem
  }

  .nav-list li a:hover {
    background-color: #ccc
  }

  .nav-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-list h3 {
    height: .45rem;
    position: relative;
    margin: 0;
    margin-bottom: .3rem;
    padding-left: 0;
    cursor: pointer;
    font-size: .32rem;
    color: #333;
    line-height: .45rem
  }

  .nav-list h3>a {
    color: #333
  }

  .nav-list h3 .header__drawer--arrow {
    transition: all .3s cubic-bezier(.51,.01,0,1);
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: .13rem;
    height: .23rem
  }

  .nav-list h3.open .header__drawer--arrow {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 40%
  }

  .nav-list h3.open::after {
    content: " ";
    position: absolute;
    left: -.32rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #0b82f1;
    width: .08rem;
    height: .36rem;
    border-top-right-radius: .08rem;
    border-bottom-right-radius: .08rem
  }
}

</style>

export default {
    'Home': '首页',
    'ProductIntroduction': '产品介绍',
    'CooperationApplication': '合作申请',
    'ChannelCooperation': '渠道合作',
    'AdCooperation': '广告合作',
    'JoinNow': '马上加入',
    'AboutUs': '关于我们',
    'QuickLinks': '快速链接',
    'ProductList': '产品列表',
    'ProductIntroduce': '产品介绍',
    'ProductTitle1': '桌面充电柜',
    'ProductTitle2': '组合充电柜',
    'ProductTitle3': '大屏充电终端',
    'ProductTitle4': '移动电源',
    'ProductTitle5': '电动自行车充电柜',
    'ContactUs': '联系我们',
    'AdContact': '广告合作邮箱',
    'BussContact': '业务合作邮箱',
    'ReportContact': '廉正举报邮箱',
    'KfContact': '客服邮箱',
    'PhoneContact': '客服电话',
    'FollowUs': '关注我们',
    'AliPay': '支付宝',
    'WeChat': '微信',
    'FooterTip': '投资有风险 选择需谨慎',
    'FooterName': '深圳街电科技有限公司',
    'FooterBA': '粤公网安备',
    'BackBtn': '回到顶部',
    'HomeContentTitle1': '全国95%覆盖',
    'HomeContentText1': '随时随地方便租借',
    'HomeContentTitle2': '归还便捷',
    'HomeContentText2': '简单操作，拒绝等待',
    'HomeContentTitle3': '免押金',
    'HomeContentText3': '信用分达标即享受免押租借',
    'HomeContentTitle4': '安全无忧',
    'HomeContentText4': '高规格品控，轻松借放心充',
    'HomeContentTitle5': '三线快充',
    'HomeContentText5': '适用于99%的手机',
    'AboutPcTitle1': '企业介绍',
    'AboutPcDesc1-1': '街电成立于2015年，致力于移动充电解决方案，为用户提供便捷及时的移动电源租借服务。街电在为用户提供优质充电体验的同时，也利用自身平台优势不断对商户进行精细化运营，为合作伙伴提供品牌宣传、产品曝光、口碑提升、引流拉新等综合解决方案。',
    'AboutPcDesc1-2': '企业愿景',
    'AboutPcDesc1-3': '精彩生活 随时随地。',
    'AboutPcDesc1-4': '携手商户形成命运共同体，共享用户、共享资源、共享数据，形成可持续的新服务经济模式。',
    'AboutPcTitle2': '历史大事记',
    'AboutPcDesc2-1': '中国“金砖奖”充换电论坛上，街电荣获“2022中国充换电行业十大最具投资价值品牌”和“2022中国十大智能安全充电桩品牌”双料奖项',
    'AboutPcDesc2-2': '街电、搜电完成合并，两大品牌母公司定名“竹芒科技”',
    'AboutPcDesc2-3': '荣膺艾媒咨询“2020年度最具投资价值企业”',
    'AboutPcDesc2-4': '街电成立五周年，累计用户近3亿',
    'AboutPcDesc2-5': '开展全国运维服务示范日行动，树业界运维服务标准',
    'AboutPcDesc2-6': '升级安全消杀级别，启动设备消毒，守护用户使用安全',
    'AboutPcDesc2-7': '驰援武汉雷神山及当地方舱医院，24小时免费使用',
    'AboutPcDesc2-8': '日订单峰值突破200万',
    'AboutPcDesc2-9': '全国95%城市覆盖，累计用户突破2亿',
    'AboutPcDesc2-10': '全球卓越成就奖—2019年度最具影响力企业/品牌',
    'AboutPcDesc2-11': '新消费产业最具影响力企业TOP50 ',
    'AboutPcDesc2-12': '全国300多座城市覆盖，日订单峰值达到180万，累计用户破亿',
    'AboutPcDesc2-13': '连续两年作为唯一共享充电宝品牌入驻世界互联网大会',
    'AboutPcDesc2-14': '支付宝官方数据显示街电小程序累计用户突破5000万，位居行业第一',
    'AboutPcDesc2-15': '获芝麻信用STS金信奖',
    'AboutPcDesc2-16': '日订单峰值达到120万，累计用户突破6000万',
    'AboutPcDesc2-17': '全国200多座城市覆盖，日订单峰值达到68万，累计用户突破2800万',
    'AboutPcDesc2-18': '在创业邦未来100领袖峰会评为“100强企业”',
    'AboutPcDesc2-19': '获得来自聚美优品3亿元战略投资',
    'AboutPcDesc2-20': '获得A轮融资，由IDG资本、欣旺达领投，联新资本跟投',
    'AboutPcDesc2-21': '街电正式成立',
    'DialogTitle': '请选择合作模式',
    'DialogTypeTitle1': '门店入驻',
    'DialogTypeText1': '我有门店，想要摆放充电宝机柜',
    'DialogTypeTitle2': '成为代理',
    'DialogTypeText2': '我想成为合伙人和街电分成',
    'DialogBtn': '立即联系'
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5';
import i18n from './i18n/i18n'
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false
import 'swiper/dist/css/swiper.css'
// 设置基准值
let sUserAgent = navigator.userAgent.toLowerCase()
let bIsWM = /mobile/.test(sUserAgent)
let baseFontSize = 0; // 这里假设设计图宽度为375px，所以基准值为20px
if(bIsWM) {
  baseFontSize = document.documentElement.clientWidth / 375 * 50
}else{
  baseFontSize = document.documentElement.clientWidth / 1200 * 62.5
}
document.documentElement.style.fontSize = `${baseFontSize}px`;
Vue.use(ElementUI);
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
